// extracted by mini-css-extract-plugin
export var Btn = "Summery-module--Btn--11a22";
export var EpiBtn = "Summery-module--EpiBtn--55c41";
export var Notes = "Summery-module--Notes--16be0";
export var NotesPara = "Summery-module--NotesPara--3b1a3";
export var SummaryHeading = "Summery-module--SummaryHeading--4ac46";
export var content = "Summery-module--content--88819";
export var ctaBg = "Summery-module--ctaBg--73170";
export var ctaImg = "Summery-module--ctaImg--9224b";
export var mike2 = "Summery-module--mike2--e3977";
export var plus = "Summery-module--plus--b34d2";
export var podlines = "Summery-module--podlines--7a6f7";
export var summary = "Summery-module--summary--2ef42";