// extracted by mini-css-extract-plugin
export var banner = "Banner-module--banner--c4a14";
export var dots = "Banner-module--dots--a17e2";
export var hover = "Banner-module--hover--8dd17";
export var imgContainer = "Banner-module--imgContainer--2fdc8";
export var modalBody = "Banner-module--modalBody--a18f8";
export var modalContent = "Banner-module--modalContent--b0764";
export var play_icon = "Banner-module--play_icon--7e2b8";
export var video = "Banner-module--video--000bb";
export var videoBanner = "Banner-module--videoBanner--5c497";
export var videoPlayBtn = "Banner-module--videoPlayBtn--e0fa8";
export var youtubeBox = "Banner-module--youtubeBox--16621";