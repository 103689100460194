import React from "react"
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ReactPlayer from "react-player/youtube"
import image from "../../images/podcast/playbtn.svg"
import dots from "../../images/podcast/poddetailsDots.svg"
import * as styles from "./Banner.module.scss"
import "../home-sections/Services.scss"

const ChildBanner = ({ podcastData }) => {
  const [show, isShow] = React.useState(true)
  const [showPop, setShowPop] = React.useState(false)

  const handleClose = () => {
    isShow(true)
    setShowPop(false)
  }
  const handleShow = () => {
    isShow(false)
    setShowPop(true)
  }
  return (
    <>
      <Modal
        show={showPop}
        onHide={handleClose}
        keyboard={false}
        centered
        size="lg"
        contentClassName={styles.modalContent}
      >
        <Modal.Body className={styles.modalBody}>
          <ReactPlayer url={podcastData?.url} playing={true} controls={true} />
        </Modal.Body>
      </Modal>

      <div className={styles.banner}>
        <div className={styles.dots}>
          <img decoding="async" loading="lazy" src={dots} alt="dots" />
        </div>
        <Row className="align-items-md-center justify-content-md-center">
          <Col md={6}>
            <div className={`${styles.videoBanner} ${styles.video}`}>
              {show && (
                <div className={styles.hover}>
                  <h5>#{podcastData?.podcast_category?.title}</h5>
                  <h1>{podcastData?.title}</h1>
                </div>
              )}
              <div className={styles.imgContainer}>
                <img
                  src={podcastData?.thumbnail?.localFile?.publicURL}
                  placeholder="blurred"
                  decoding="async"
                  loading="lazy"
                  alt="play_invozone_video"
                  style={{
                    borderRadius: 7,
                    height: "100%",
                    maxWidth: "100%",
                    width: "100%",
                    zIndex: 0,
                  }}
                />
                <img
                  decoding="async"
                  loading="lazy"
                  alt="play"
                  src={image}
                  height="70px"
                  onClick={handleShow}
                  id="hp_video_section_gt"
                  className={`pointerImage ${styles.videoPlayBtn}`}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default ChildBanner
