import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share"
import SEORevamp from "../components/common/SEO_Revamp"
import ChildBanner from "../components/podcast-detail/Banner"
import Episode from "../components/podcast-detail/Episode"
import Summary from "../components/podcast-detail/Summary"
import Banner from "../components/podcast/Banner"
import * as styles from "../components/saas-files/blogPost.module.scss"
import twitter from "../images/react-icons/twitter white logo.svg"
import MainLayout from "../layouts/MainLayout"
import "../components/event-details-page/EventDetails.scss"

const PodcastDetail = ({ pageContext }) => {
  const singlePost = pageContext?.currentPod
  const { metaTitle, metaDescription } = singlePost?.seo
  const url = `https://invozone.com/podcast/${singlePost?.slug}/`

  return (
    <MainLayout podcast={true}>
      <div className={styles.parrent}>
        <Banner>
          <div className="Socila-share">
            <FacebookShareButton url={url} quote={metaDescription} hashtag={[]}>
              <FacebookIcon size={32} borderRadius={5} />
            </FacebookShareButton>

            <LinkedinShareButton
              url={url}
              title={metaTitle}
              summary={metaDescription}
              source=""
            >
              <LinkedinIcon size={32} borderRadius={5} />
            </LinkedinShareButton>

            <TwitterShareButton
              url={url}
              title={metaTitle}
              via=""
              hashtags={[]}
            >
              <div
                style={{
                  height: "32px",
                  width: "32px",
                  justifyContent: "center",
                  display: "flex",
                  background: "#1e9cf0",
                  borderRadius: "3px",
                  margin: "auto",
                }}
              >
                <img
                  src={twitter}
                  borderRadius={5}
                  decoding="async"
                  loading="lazy"
                  alt="twitter"
                  style={{ width: "16px" }}
                />
              </div>
            </TwitterShareButton>
            <RedditShareButton url={url} title={metaTitle}>
              <RedditIcon size={32} borderRadius={5} />
            </RedditShareButton>
            <EmailShareButton
              url={url}
              subject={metaTitle}
              body={metaDescription}
            >
              <EmailIcon size={32} borderRadius={5} />
            </EmailShareButton>
            <WhatsappShareButton url={url} title={metaTitle}>
              <WhatsappIcon size={32} borderRadius={5} />
            </WhatsappShareButton>
          </div>
          <ChildBanner podcastData={singlePost} />
        </Banner>
        <Summary podcastData={singlePost} />
        <Episode
          podcastData={singlePost}
          stories={pageContext?.allPods}
          currentPod={singlePost?.slug}
        />
        <Container>
          {" "}
          <Row className="justify-content-center">
            <div className="Socila-share-mobile pb-5">
              <FacebookShareButton
                url={url}
                quote={metaDescription}
                title={metaTitle}
                hashtag={[]}
              >
                <FacebookIcon size={32} borderRadius={5} />
              </FacebookShareButton>

              <LinkedinShareButton
                url={url}
                title={metaTitle}
                summary={metaDescription}
                source=""
              >
                <LinkedinIcon size={32} borderRadius={5} />
              </LinkedinShareButton>

              <TwitterShareButton
                url={url}
                title={metaTitle}
                via=""
                hashtags={[]}
              >
                <div
                  style={{
                    height: "32px",
                    width: "32px",
                    justifyContent: "center",
                    display: "flex",
                    background: "#1e9cf0",
                    borderRadius: "3px",
                    margin: "auto",
                  }}
                >
                  <img
                    src={twitter}
                    borderRadius={5}
                    decoding="async"
                    loading="lazy"
                    alt="twitter"
                    style={{ width: "16px" }}
                  />
                </div>
              </TwitterShareButton>
              <RedditShareButton url={url} title={metaTitle}>
                <RedditIcon size={32} borderRadius={5} />
              </RedditShareButton>
              <EmailShareButton
                url={url}
                title={metaTitle}
                body={metaDescription}
              >
                <EmailIcon size={32} borderRadius={5} />
              </EmailShareButton>
              <WhatsappShareButton url={url} title={metaTitle}>
                <WhatsappIcon size={32} borderRadius={5} />
              </WhatsappShareButton>
            </div>
          </Row>
        </Container>
      </div>
    </MainLayout>
  )
}

export default PodcastDetail

export const Head = ({ pageContext }) => {
  const singlePost = pageContext?.currentPod
  const { metaTitle, metaDescription, keywords } = singlePost?.seo
  const url = `https://invozone.com/podcast/${singlePost?.slug}/`

  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      keywords={keywords}
      image={`https://invozone.com${singlePost?.thumbnail?.localFile?.publicURL}`}
      url={url}
    />
  )
}
