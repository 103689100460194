import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import mike2 from "../../images/podcast/mike2.svg"
import plus from "../../images/podcast/plus.svg"
import podlines from "../../images/podcast/podlines.svg"
import * as styles from "./Summery.module.scss"
import { Link } from "gatsby"
import { isValidHttpUrl } from "../../utils"

function Summary({ podcastData }) {
  return (
    <div className={styles.summary}>
      <Container>
        <Row>
          <Col lg={9} md={12}>
            <div className={styles.SummaryHeading}>
              {" "}
              <h2>Summary of the Episode</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: podcastData.summary,
                }}
              />
            </div>
            <div className={styles.Notes}>
              <h2>Show Notes</h2>
              <div
                className={styles.NotesPara}
                dangerouslySetInnerHTML={{
                  __html: podcastData?.description,
                }}
              />
              <div className={styles.mike2}>
                <img decoding="async" loading="lazy" src={mike2} alt="mike" />
              </div>
              <div className={styles.podlines}>
                <img
                  decoding="async"
                  loading="lazy"
                  src={podlines}
                  alt="lines"
                />
              </div>
              <div className={styles.plus}>
                <img decoding="async" loading="lazy" src={plus} alt="plus" />
              </div>
            </div>
          </Col>
          <Col lg={3} md={12}>
            {podcastData?.podcast_cta && (
              <div className={styles.ctaImg}>
                <img
                  src={podcastData?.podcast_cta?.image?.url}
                  alt={podcastData?.podcast_cta?.image?.alternativeText}
                  decoding="async"
                  loading="lazy"
                />
                <div className={styles.ctaBg}>
                  <div className={styles.content}>
                    <h2>{podcastData?.podcast_cta?.title}</h2>
                    <p>{podcastData?.podcast_cta?.subTitle}</p>
                    <div className={styles.Btn}>
                      {isValidHttpUrl(podcastData?.podcast_cta?.button?.url) ? (
                        <a
                          href={`${podcastData?.podcast_cta?.button?.url}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <button
                            className={styles.EpiBtn}
                            // onClick={() => navigate(`/podcast/${item?.slug}/`)}
                          >
                            {podcastData?.podcast_cta?.button?.title}
                          </button>
                        </a>
                      ) : (
                        <Link to={`${podcastData?.podcast_cta?.button?.url}`}>
                          <button
                            className={styles.EpiBtn}
                            // onClick={() => navigate(`/podcast/${item?.slug}/`)}
                          >
                            {podcastData?.podcast_cta?.button?.title}
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Summary
