import { navigate } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import PlayBtn2 from "../../images/carbon_play-filled-alt.svg"
import podcastDots from "../../images/podcast/podcastDots.png"
import * as styles from "./Episode.module.scss"

function Episode({ podcastData, stories, currentPod }) {
  const filter = stories?.filter(
    e =>
      e?.podcast_category?.title.toLowerCase() ===
        podcastData?.podcast_category?.title.toLowerCase() &&
      e.slug !== currentPod &&
      e.isUpComming !== true
  )

  return filter.length < 1 ? (
    <div className={styles.EpiHeading}></div>
  ) : (
    <Container>
      <div className={styles.podcastDots}>
        <img decoding="async" loading="lazy" src={podcastDots} alt="dots" />
      </div>
      <div className={styles.EpiHeading}>
        <h2>Similar Episodes</h2>
        <Row>
          {filter?.map(
            (item, index) =>
              index < 3 && (
                <Col lg={4} key={index}>
                  <div
                    onClick={() => navigate(`/podcast/${item?.slug}/`)}
                    className={styles.EpiBg}
                    style={{
                      backgroundImage: `url(${item?.thumbnail?.localFile?.publicURL})`,
                    }}
                  >
                    <h3>{item?.title}</h3>
                    <div className={styles.BlueLine}></div>
                  </div>
                  <div className={styles.Btn}>
                    {" "}
                    <button
                      className={styles.EpiBtn}
                      onClick={() => navigate(`/podcast/${item?.slug}/`)}
                    >
                      Listen Now
                      <img
                        decoding="async"
                        loading="lazy"
                        alt="play button"
                        src={PlayBtn2}
                        style={{ paddingLeft: "7px" }}
                      />
                    </button>
                  </div>
                </Col>
              )
          )}
        </Row>
      </div>
    </Container>
  )
}

export default Episode
